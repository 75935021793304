import * as React from "react";
import AbstractDataStore from "../../abstracts/AbstractDataStore";
import {
    isApiAccessDeniedError,
    isApiNotAuthorizedError,
    isApiNotFoundError
} from "@ova-studio/api-helper";

const Page403 = React.lazy(() => import('../../AppWrapper/AppPageLayout/PageError/Page403'));
const Page404 = React.lazy(() => import('../../AppWrapper/AppPageLayout/PageError/Page404'));
const PageGuest = React.lazy(() => import('../../AppWrapper/AppPageLayout/PageError/PageGuest'));

type PageErrorDefinition<T extends unknown> = {
    check: (error: unknown) => error is T;
    element: React.ElementType;
}

type AddMode = 'append' | 'prepend';

export type ErrorHelperData = {
    lock: React.ElementType|null;
    pageErrors: PageErrorDefinition<any>[];
};

export default class ErrorHelper extends AbstractDataStore<ErrorHelperData> {

    private _data: ErrorHelperData = {
        lock: null,
        pageErrors: [],
    }

    constructor() {
        super();

        this.registerPageError({ check: isApiNotFoundError, element: Page403 });
        this.registerPageError({ check: isApiAccessDeniedError, element: Page404 });
        this.registerPageError({ check: isApiNotAuthorizedError, element: PageGuest });
    }

    public registerPageError<T extends unknown>(opts: PageErrorDefinition<T>, mode: AddMode = 'append'): void {
        this._data = {
            ...this._data,
            pageErrors: mode === 'append'
                ? [...this._data.pageErrors, opts]
                : [opts, ...this._data.pageErrors],
        }
    }

    public lock(element: React.ElementType): void {
        this._data = {
            ...this._data,
            lock: element,
        };
        this._callListeners();
    }

    public unlock(): void {
        this._data = {
            ...this._data,
            lock: null,
        }
        this._callListeners();
    }

    public getData() : ErrorHelperData {
        return this._data;
    }

}