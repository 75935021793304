import * as React from "react";
import {ModuleConfig} from "../_registry";
import {makeCrudRoute} from "@ova-studio/react-hyper-admin";

const CommentsList = React.lazy(() => import('./pages/CommentsList'))
const CommentInfo = React.lazy(() => import('./pages/CommentInfo'))

const config: ModuleConfig = {

    navigation: [
        {
            order: 300,
            item: {
                url: '/comments',
                icon: 'comment-multiple-outline',
                name: 'Коментарі',
                counters: {
                    'comments-wait-cnt': 'warning',
                },
            },
        },
    ],

    routes: [
        makeCrudRoute({
            type: 'simple',
            basePath: 'comments',
            baseEndpoint: 'comments',
            name: 'Коментарі',
            index: {
                name: 'Список коментарів',
                wrapper: CommentsList,
            },
            show: {
                name: 'Інформація про коментар #{data.id}',
                element: <CommentInfo />,
                relations: {
                    'votes': 'votes',
                    'feedback': 'feedback',
                }
            },
        })
    ]
}

export default config;
