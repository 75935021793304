import AbstractDataStore from "../../abstracts/AbstractDataStore";
import {
    isNavMenuCounterList,
    isNavMenuCounterUpdateData,
    NavigationMenu, NavMenuCounterList,
} from "./types/MainNavigation";
import {NavigationConfig} from "./types/NavigationConfig";
import {App} from "../../Application";
import {SimpleCallback} from "../../types/SimpleCallback";

export type MainNavigationData = {
    data: NavigationMenu|null|false,
    counters: NavMenuCounterList,
    isOpen: boolean,
}

export default class MainNavigation extends AbstractDataStore<MainNavigationData> {

    private _data: MainNavigationData = {
        data: null,
        counters: {},
        isOpen: false,
    };

    private readonly _app: App;
    private readonly _config: NavigationConfig;

    constructor(app: App, config: NavigationConfig) {
        super();

        this._app = app;
        this._config = config;
    }

    private _updateData(data: Partial<MainNavigationData>, silent: boolean = false) {
        this._data = {
            ...this._data,
            ...data,
        };

        if (!silent) {
            this._callListeners();
        }
    }

    public async init() : Promise<SimpleCallback> {
        if (!this._config.navigationMenuResolver) {
            this._updateData({ data: false });
            return () => {};
        }

        try {
            this._updateData({
                data: await this._config.navigationMenuResolver(this._app),
            }, true);
            await this._initCounters();
            this._callListeners();
        } catch (e) {
            this._updateData({ data: false });
            this._callListeners();
        }

        return () => {};
    }

    private _handleCounterUpdate(data: any) {
        if (!isNavMenuCounterUpdateData(data)) {
            console.error("Invalid badge update data", data);
            return;
        }

        this._updateData({
            counters: {
                ...this._data.counters,
                [data.name]: data.value,
            }
        });
    }

    private async _initCounters() {
        if (!this._config.navigationCountersResolver) {
            return;
        }

        const data = await this._config.navigationCountersResolver(this._app);

        if (!isNavMenuCounterList(data)) {
            console.error("Invalid initial badge list data", data);
            return;
        }

        this._updateData({ counters: data }, true);

        this._app.websocket.listen('App.Navigation', '.counter.updated', this._handleCounterUpdate.bind(this));
    }

    public toggle(state?: boolean) {
        this._updateData({
            isOpen: state !== undefined ? state : !this._data.isOpen,
        });
    }

    public getData() : MainNavigationData {
        return this._data;
    }
}
