import * as React from 'react';
import BottomMenu, { BottomMenuFallback } from "./BottomMenu";
import SideMenu, { SideMenuFallback } from "./SideMenu";
import useExternalStore from "../../../hooks/useExternalStore";
import {NavFallbackProps} from "./NavFallbackProps";
import {useAppNavigation} from "../../../Application";

const MainNavFallback = ({ state }: NavFallbackProps) => {
    return (
        <React.Fragment>
            <BottomMenuFallback state={state} />
            <SideMenuFallback state={state} />
        </React.Fragment>
    );
}

const MainNav = () => {

    const appNavigation = useAppNavigation();

    React.useEffect(() => {
        void appNavigation.init();
        return () => appNavigation.destroy();
    }, [])

    const {data, isOpen} = useExternalStore(appNavigation.MainNavigation);

    if (data === null) {
        return <MainNavFallback state='loading' />
    }

    if (!data) {
        return <MainNavFallback state='error' />
    }

    const items = data.slice(0, 3);

    return (
        <React.Fragment>
            {items && <BottomMenu items={items} />}
            <SideMenu data={data} isOpen={isOpen} />
        </React.Fragment>
    )
}

export default MainNav
