import * as React from 'react';
import {useRouteError} from "react-router-dom";
import {useAppErrorHelper} from "../../Application";
import useExternalStore from "../../hooks/useExternalStore";
import Preloader from "../../components/Preloader";

const PageErrorBoundary = () => {
    const error = useRouteError();
    const appErrorHelper = useAppErrorHelper();
    const { pageErrors } = useExternalStore(appErrorHelper);

    for (const { check, element: Element } of pageErrors) {
        if (check(error)) {
            return (
                <React.Suspense fallback={<Preloader />}>
                    <Element />
                </React.Suspense>
            )
        }
    }

    throw error;
}

export default PageErrorBoundary
