import * as React from "react";
import {NavLink} from "react-router-dom";
import {isNavMenuIcon, isNavMenuLink, NavMenuItem} from "../../../../../AppHelpers/Navigation";
import {SimpleCallback} from "../../../../../types/SimpleCallback";
import classNames from "classnames";
import NavCounters from "../../NavCounters";

type BottomMenuItemLinkProps = {
    data: NavMenuItem,
    handleClick: SimpleCallback,
}

const BottomMenuItemLink = ({ data, handleClick }: BottomMenuItemLinkProps) => {
    const isLink = isNavMenuLink(data);

    return (
        <NavLink to={isNavMenuLink(data) ? data.url : '#'} className={classNames('bottom-menu-item arrow-none', isLink && 'bottom-menu-item-link', data.disabled && 'bottom-menu-item-disabled')} onClick={handleClick} aria-disabled={data.disabled}>
            {isNavMenuIcon(data) && <i className={`mdi mdi-${data.icon}`} />}
            <span>
                {data.name}
                {data.counters && <NavCounters data={data.counters} />}
            </span>
        </NavLink>
    )
}

export default BottomMenuItemLink;