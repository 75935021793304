import {RouteObject} from "react-router-dom";
import {App, FirstLevelNavMenuItem} from "@ova-studio/react-hyper-admin";

export type ModuleName = string;
export type ModuleStates = Record<ModuleName, boolean>;

export type ModuleNavigationConfig = {
    order: number,
    item: FirstLevelNavMenuItem,
}

export type ModuleConfig = {
    init?: (app: App) => void,
    navigation?: ModuleNavigationConfig | ModuleNavigationConfig[],
    routes?: RouteObject | RouteObject[],
}

export const isModuleStates = (value: any): value is ModuleStates => {
    if (typeof value !== 'object' || value === null) {
        return false;
    }
    for (const key in value) {
        if (typeof value[key] !== 'boolean') {
            return false;
        }
    }
    return true;
}
