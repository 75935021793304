import * as React from 'react';
import classNames from "classnames";

export type FlexCenteredProps = {
    addClassName?: string,
    children: React.ReactNode,
    fullPage?: boolean
}

const FlexCentered = ({ addClassName, children, fullPage = false }: FlexCenteredProps) => {
    return (
        <div className={classNames('d-flex flex-column align-items-center justify-content-center', fullPage ? 'full-page' : 'h-100', addClassName)}>
            {children}
        </div>
    );
}

export default FlexCentered
