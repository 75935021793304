import * as React from "react";
import {App} from "@ova-studio/react-hyper-admin";
import {ModulesRegistry} from "../modules/_registry";
import {MediaLibrary} from "@ova-studio/react-media-library";
import {isFeatureUnavailableError} from "./pages/FeatureUnavailable/isFeatureUnavailable";
import API, {isApiError} from "@ova-studio/api-helper";

const LockPage = React.lazy(() => import('./pages/LockPage'));
const FeatureUnavailablePage = React.lazy(() => import('./pages/FeatureUnavailable/FeatureUnavailablePage'));

const onAppReady = (app: App) => {
    ModulesRegistry.initModules(app);
    MediaLibrary.initializer(app);

    app.errorHelper.registerPageError({
        check: isFeatureUnavailableError,
        element: FeatureUnavailablePage,
    })

    API.registerGlobalErrorHandler((e: unknown) => {
        if (!isApiError(e)) return;

        if (e.response?.status === 402) {
            app.errorHelper.lock(LockPage);
        }
    });
}

export default onAppReady;
