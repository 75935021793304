import * as React from "react";
import {ModuleConfig} from "../_registry";
import {makeSimpleRoute} from "@ova-studio/react-hyper-admin";

const MediaIndex = React.lazy(() => import('./pages/MediaIndex'))

const MediaModuleConfig : ModuleConfig = {
    navigation: {
        order: 400,
        item: {
            url: '/media',
            icon: 'folder-multiple-image',
            name: 'Медіа',
        }
    },

    routes: [
        makeSimpleRoute({
            name: 'Медіа',
            path: 'media',
            element: <MediaIndex />,
        })
    ]
}

export default MediaModuleConfig;
