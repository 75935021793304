import * as React from "react";
import {DuplicateAction, UploadingFiles, UploadItem, UploadStatus} from "../types/UploadFile";
import {Button, Dropdown, ProgressBar} from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";

export type UploadStatusDisplayProps = {
    data: UploadItem,
}

const duplicateActionsNames : Record<DuplicateAction, string> = {
    [DuplicateAction.Reuse]: 'Використати існуючий',
    [DuplicateAction.Regenerate]: 'Перегенерувати',
    [DuplicateAction.KeepBoth]: 'Зберегти обидва',
}

const UploadStatusDisplay = ({ data }: UploadStatusDisplayProps) => {
    if (data.status === UploadStatus.Success) {
        return (
            <ProgressBar
                variant='success'
                now={100}
                label='Успішно завантажено'
            />
        )
    }

    if (data.status === UploadStatus.WaitDuplicateConfirm) {
        return (
            <div className='d-flex align-items-center'>
                <div>Медіа вже існує</div>
                {data.duplicateHandler && (
                    <Dropdown as={ButtonGroup} size='sm' className='ms-auto' drop='up'>
                        <Button variant='outline-secondary' onClick={() => data.duplicateHandler?.(DuplicateAction.Reuse)} className='p-0 px-1'>
                            {duplicateActionsNames[DuplicateAction.Reuse]}
                        </Button>
                        <Dropdown.Toggle split variant='outline-secondary' className='p-0 px-1' />
                        <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} renderOnMount={true}>
                            {[DuplicateAction.Regenerate, DuplicateAction.KeepBoth].map((action, idx) => (
                                <Dropdown.Item
                                    key={`btn-${idx}`}
                                    onClick={() => data.duplicateHandler?.(action)}
                                    children={duplicateActionsNames[action]}
                                />
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>
        )
    }

    if (data.status === UploadStatus.Error) {
        return <div className='text-danger'>{data.error ?? 'Помилка завантаження'}</div>
    }

    return (
        <ProgressBar
            now={data.progress}
            label={data.progress > 99 ? 'Іде обробка, зачекайте...' : `${data.progress}%`}
        />
    )
}

export type UploadToastBodyProps = {
    data: UploadingFiles,
}

const UploadToastBody = ({ data }: UploadToastBodyProps) => {
    return (
        <div className='scroll-vertical custom-scrollbar' style={{ maxHeight: '40vh' }}>
            <div className='d-flex flex-column gap-2'>
                {Object.values(data).map((item, idx) => (
                    <div key={idx}>
                        <div className='w-100 overflow-hidden text-truncate fw-bold mb-1'>{item.name}</div>
                        <UploadStatusDisplay data={item} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default UploadToastBody;
