import * as React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import {useMediaLibraryService} from "../../services/MediaLibraryService";
import {MediaType} from "../../types/MediaType";
import clsx from "clsx";

const UploadButton = () => {

    const service = useMediaLibraryService();
    const uploadService = service.upload;

    const disabled = !service.isTypeAllowed(MediaType.File);

    return (
        <Dropdown as={ButtonGroup}>
            <Button variant="outline-success" size='sm' onClick={uploadService.openUploadFileDialog.bind(uploadService)}>
                <i className='mdi mdi-upload me-1' />
                Завантажити
            </Button>

            <Dropdown.Toggle split variant="outline-success" size='sm' />

            <Dropdown.Menu>
                <Dropdown.Item onClick={uploadService.openUploadLinkDialog.bind(uploadService, undefined)} disabled={disabled} className={clsx(disabled && 'text-muted', 'user-select-none')}>
                    <i className='mdi mdi-link me-1' />
                    Вставка з соцмереж
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default UploadButton
