import * as React from "react";
import {App, SimpleCallback} from "@ova-studio/react-hyper-admin";
import API, {getApiErrorMessage, isApiError} from "@ova-studio/api-helper";
import {
    DuplicateAction,
    RequestData,
    UploadingFiles,
    UploadItem,
    UploadItemInfo,
    UploadStatus,
    UploadTask,
    UploadType
} from "../types/UploadFile";
import MediaLibraryService from "./MediaLibraryService";
import {Media} from "../types/Media";
import {v4 as uuid} from "uuid";
import {FolderID} from "../types/MediaFolder";
import {AxiosProgressEvent} from "axios";
import UploadToastBody from "../MediaLibrary/UploadToastBody";

type RelatedUploadHandler = (promise: Promise<Media[]>) => void;

type RelatedBlockOptions = {
    block: HTMLElement,
    handler: RelatedUploadHandler,
    message?: string | ((cnt: number) => string),
}

type ToastData = ReturnType<typeof App.prototype.toasts.createToast>;

export default class UploadService {

    private readonly _service: MediaLibraryService;
    private readonly _uploadInput: HTMLInputElement;
    private readonly _dropPopup: HTMLDivElement;

    private readonly _toast: ToastData;
    private _toastHideTimeout: NodeJS.Timeout|undefined;

    private _uploadingFiles: UploadingFiles = {};

    private _relatedBlocks: RelatedBlockOptions[] = [];
    private _allowedMimeTypes: string[]|undefined = undefined;

    constructor(service: MediaLibraryService) {
        this._service = service;
        this._uploadInput = this._initUploadInput();
        this._dropPopup = this._initDropPopup();

        void this._loadAllowedMimeTypes();

        this._toast = this._service.app.toasts.createToast({
            hidden: true,
            icon: 'upload',
            title: 'Завантаження медіа',
            closeAction: 'hide',
            body: this._resolveUploadToastBody(),
            disableClose: true,
            onClose: this._handleToastClose.bind(this),
        });

        window.addEventListener('beforeunload', this._handleUnload.bind(this));

        window.addEventListener('dragenter', this._handleDragEvent.bind(this));
        window.addEventListener('dragover', this._handleDragEvent.bind(this));
    }

    private async _loadAllowedMimeTypes() : Promise<void> {
        try {
            this._allowedMimeTypes = await API.getData<string[]>(this._service.getEndpoint('helpers/allowed-mime-types'));
            this._uploadInput.accept = this.allowedMimeTypes;
        } catch (e) {
            // ignore
        }
    }

    public get allowedMimeTypes() : string {
        return this._allowedMimeTypes?.join(',') ?? 'error/error';
    }

    private _handleToastClose() {
        this._uploadingFiles = Object.entries(this._uploadingFiles)
            .filter(([_, i]) => [UploadStatus.Uploading, UploadStatus.WaitDuplicateConfirm].includes(i.status))
            .reduce((acc, [id, i]) => ({ ...acc, [id]: i }), {});
    }

    private _resolveUploadToastBody() : React.ReactNode {
        return React.createElement(UploadToastBody, { data: this._uploadingFiles });
    }

    private _updateToast() {
        const disableClose = Object.values(this._uploadingFiles).some(i => [UploadStatus.Uploading, UploadStatus.WaitDuplicateConfirm].includes(i.status));
        this._toast.update({
            body: this._resolveUploadToastBody(),
            disableClose: disableClose,
        });

        if (!disableClose) {
            if (this._toastHideTimeout) {
                clearTimeout(this._toastHideTimeout);
            }

            this._toastHideTimeout = setTimeout(() => {
                this._toast.hide();
                this._toastHideTimeout = undefined;
            }, 5000);

            return;
        }

        this._toast.show();
    }

    private _handleInputChanged(e: Event) {
        e.preventDefault();

        if (e.target instanceof HTMLInputElement && e.target.files) {
            if (e.target.files.length > 0) {
                void this.uploadFiles(e.target.files);
            }
            e.target.value = '';
        }
    }

    private _initUploadInput() : HTMLInputElement {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.style.display = 'none';
        input.addEventListener('change', this._handleInputChanged.bind(this));
        document.body.appendChild(input);

        return input;
    }

    private _initDropPopup() : HTMLDivElement {
        const div = document.createElement('div');
        div.className = 'media-library-drop-popup';
        div.style.display = 'none';
        div.addEventListener('dragover', this._handleDragEvent.bind(this));
        div.addEventListener('dragleave', this._handleDragEvent.bind(this));
        div.addEventListener('drop', this._handleDragEvent.bind(this));
        document.body.appendChild(div);

        return div;
    }

    private set _dragPopupEnabled(value: boolean) {
        this._dropPopup.style.display = value ? '' : 'none';
    }

    private _handleUnload(e: BeforeUnloadEvent) : string|undefined {
        if (Object.values(this._uploadingFiles).some(i => i.status === UploadStatus.Uploading)) {
            e.preventDefault();
            return 'Завантаження медіа ще не завершено';
        }

        return undefined;
    }

    private _updateBlockMessage(posX: number, posY: number, cnt: number) {
        const block = this._getRelatedBlock(posX, posY);

        if (!block) {
            this._dropPopup.removeAttribute('data-message');
            return;
        }

        const message = typeof block.message === 'function' ? block.message(cnt) : block.message;

        if (message) {
            this._dropPopup.setAttribute('data-message', message);
        } else {
            this._dropPopup.removeAttribute('data-message');
        }
    }

    private _isMediaModalOpen() : boolean {
        return !!document.querySelector('.media-library-modal')?.classList.contains('show');
    }

    private _getRelatedBlock(posX: number, posY: number) : RelatedBlockOptions|undefined {
        if (this._isMediaModalOpen()) {
            return undefined;
        }

        return this._relatedBlocks.find(i => {
            const rect = i.block.getBoundingClientRect();
            return posX >= rect.left && posX <= rect.right && posY >= rect.top && posY <= rect.bottom;
        });
    }

    private _handleDragUpload(files: FileList, posX: number, posY: number) {
        const block = this._getRelatedBlock(posX, posY);

        const promise = this.uploadFiles(files);
        block?.handler(promise);
    }

    private _handleDragEvent(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "dragenter" || e.type === "dragover") {
            if (e.dataTransfer?.items?.[0]?.kind === 'file') {
                this._dragPopupEnabled = true;
                this._updateBlockMessage(e.clientX, e.clientY, e.dataTransfer.items.length);
            }
        } else if (e.type === "dragleave") {
            this._dragPopupEnabled = false;
        } else if (e.type === "drop") {
            this._dragPopupEnabled = false;
            if (!!e.dataTransfer?.files?.length) {
                this._handleDragUpload(e.dataTransfer.files, e.clientX, e.clientY);
            }
        }
    }

    public initDropdownIframe(rootNode: HTMLElement, opts: RelatedBlockOptions) : SimpleCallback {
        const handleIframeDrag = (e: DragEvent) => {
            if (e.dataTransfer?.items?.[0]?.kind === 'file') {
                this._handleDragEvent(e);
            }
        }

        rootNode.addEventListener('dragenter', handleIframeDrag);
        rootNode.addEventListener('dragover', handleIframeDrag);

        this._relatedBlocks.push(opts);

        return () => {
            rootNode.removeEventListener('dragenter', handleIframeDrag);
            rootNode.removeEventListener('dragover', handleIframeDrag);
            this._relatedBlocks = this._relatedBlocks.filter(i => i !== opts);
        }
    }

    public addMedia(media: Media) : void {
        this._service.mediaManager.fire('created', media);
    }

    private _makeUploadItem(info: UploadItemInfo) : string {
        const id = uuid();

        const data = {
            ...info,
            status: UploadStatus.Uploading,
            progress: 0,
        }

        this._uploadingFiles = {
            ...this._uploadingFiles,
            [id]: {
                ...data,
            }
        }

        this._updateToast();

        return id;
    }

    private _updateUploadItem(id: string, data: Partial<UploadItem>) : void {
        const item = this._uploadingFiles[id];

        if (!item) {
            throw new Error(`Upload item with id ${id} not found`);
        }

        const newData = {
            ...item,
            ...data,
        }

        this._uploadingFiles = {
            ...this._uploadingFiles,
            [id]: newData,
        }

        this._updateToast();
    }

    private get _uploadFolder() : FolderID|null {
        return this._service.folderManager.realSelectedFolder;
    }

    private _waitDuplicateConfirm(itemId: string) : Promise<DuplicateAction> {
        return new Promise<DuplicateAction>((resolve) => {
            const handle = (action: DuplicateAction) => {
                this._updateUploadItem(itemId, { status: UploadStatus.Uploading, progress: 0 });
                resolve(action);
            }

            this._updateUploadItem(itemId, {
                status: UploadStatus.WaitDuplicateConfirm,
                progress: 0,
                duplicateHandler: handle,
            });
        });
    }

    private async _handleUploadError(itemId: string, e: unknown, payload: RequestData) : Promise<Media> {
        if (isApiError(e) && e.response?.status === 409) {
            const action = await this._waitDuplicateConfirm(itemId);
            return await this._upload(itemId, { ...payload, duplicateAction: action });
        }

        const errMsg = getApiErrorMessage(e);
        this._updateUploadItem(itemId, { status: UploadStatus.Error, progress: 100, error: errMsg });
        throw new Error(errMsg);
    }

    private async _upload(itemId: string, data: RequestData) : Promise<Media> {

        const onUploadProgress = (progressEvent : AxiosProgressEvent) => {
            if (!progressEvent.total) return;

            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            this._updateUploadItem(itemId, { progress });
        }

        const payload = {
            ...data,
            folder: this._uploadFolder,
            context: this._service.currentContext,
        }

        try {
            const { data: media } : { data: Media } = await API.postWithFile(this._service.getEndpoint('media'), payload, { onUploadProgress, timeout: 10 * 60000 }); // 10 minutes
            this._updateUploadItem(itemId, { status: UploadStatus.Success, progress: 100 });
            return media;
        } catch (e : any) {
            return await this._handleUploadError(itemId, e, payload);
        }
    }

    private async _uploadMulti(items : UploadTask[]) : Promise<Media[]> {
        const media = await Promise.all(
            items.map(item => {
                const itemId = this._makeUploadItem(item.info);
                return this._upload(itemId, item.payload);
            })
        )

        media.forEach(i => this._service.mediaManager.fire('created', i));

        const waitNameMedia = media.filter(m => m.flags.isNameRequired && !m.meta_data?.name);
        if (waitNameMedia.length > 0) {
            await this._service.mediaManager.openMediaData(waitNameMedia);
        }

        return media;
    }

    public uploadFiles(files: FileList) : Promise<Media[]> {
        if (files.length === 0) {
            return Promise.resolve([]);
        }

        return this._uploadMulti(
            Array.from(files)
                .map(file => ({ payload: { file, type: UploadType.File }, info: { type: UploadType.File, name: file.name, size: file.size } }))
        );
    }

    public openUploadFileDialog() : void {
        if (!this._allowedMimeTypes) {
            this._service.app.toasts.createToast({
                title: 'Помилка',
                body: 'Не вдалося завантажити список дозволених типів файлів',
                variant: 'danger',
            });
            return;
        }

        this._uploadInput.click();
    }

    public addFromLinks(links: string[], handler?: RelatedUploadHandler) : Promise<Media[]> {
        if (links.length === 0) {
            return Promise.resolve([]);
        }

        const promise = this._uploadMulti(
            links.map(link => ({ payload: { link, type: UploadType.Link }, info: { type: UploadType.Link, name: link } }))
        )

        if (typeof handler === 'function') {
            handler(promise);
        }

        return promise;
    }

    public openUploadLinkDialog(handler?: RelatedUploadHandler) : void {
        this._service.states?.uploadLinkModal.setData({ handler });
        this._service.states?.uploadLinkModal.open();
    }
}
