import * as React from "react";
import {ModuleConfig} from "../_registry";
import {makeCrudRoute} from "@ova-studio/react-hyper-admin";

const PostDraftCreateHelper = React.lazy(() => import('./PostDraftCreateHelper'))
const postsDatatableRenderConfig = () => import('./datatable/postsDatatableRenderConfig')
const postDraftsDatatableRenderConfig = () => import('./datatable/postDraftsDatatableRenderConfig')
const PostEditForm = React.lazy(() => import('./pages/PostEditForm'))
const PublicPostInitLoader = React.lazy(() => import('./pages/PublicPostInitLoader'))

const PostsModuleConfig : ModuleConfig = {
    init: (app) => {
        app.helpers.register('posts.draft.create-modal', PostDraftCreateHelper, 'auth');
    },

    navigation: [
        {
            order: 100,
            item: {
                url: '/posts/drafts',
                icon: 'file-document-edit',
                name: 'Чернетки',
            },
        },
        {
            order: 200,
            item: {
                url: '/posts/public',
                icon: 'file-document-check-outline',
                name: 'Опубліковані',
            },
        },
    ],

    routes: [
        makeCrudRoute({
            type: 'simple',
            basePath: 'posts/drafts',
            baseEndpoint: 'posts/drafts',
            name: 'Чернетки',
            index: {
                name: 'Чернетки',
                renderConfig: postDraftsDatatableRenderConfig,
            },
            update: {
                name: 'Редагування публікації',
                element: <PostEditForm />,
                // relations: {
                //     'data': {
                //         isSync: true,
                //         endpoint: 'data',
                //         condition: () => true,
                //     },
                // },
                helpers: {
                    authors: 'helpers/posts/authors',
                    categories: 'helpers/posts/categories',
                    options: 'helpers/posts/options',
                    ['other/ukr-net-categories']: 'helpers/posts/other/ukr-net-categories',
                    ['meta/comments/enable-status']: 'helpers/comments/enable-status',
                },
            }
        }),
        makeCrudRoute({
            type: 'simple',
            basePath: 'posts/public',
            baseEndpoint: 'posts/public',
            name: 'Опубліковані',
            index: {
                name: 'Опубліковані',
                renderConfig: postsDatatableRenderConfig,
            },
            update: {
                name: 'Редагування публікації',
                element: <PublicPostInitLoader />,
            },
        }),
    ]
}

export default PostsModuleConfig;
