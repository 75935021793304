import {AppConfig} from "@ova-studio/react-hyper-admin";
import Logo from "../assets/Logo";
import searchTypes from "./searchTypes";
import Auth from "./Auth";
import {ModulesRegistry} from "../modules/_registry";
import {EditorConfig} from "@ova-studio/react-block-editor";

export type OvaEngineAppConfig = {
    sentryDsn?: string;
    appRelease?: string;
}

export type OvaEngineEditorConfig = Partial<EditorConfig>;

const adminDomain = window.location.hostname;
const siteDomain = adminDomain.replace('admin.', '');

const appConfig : AppConfig = {
    base: {
        appName: 'OVA Engine',
        appCreateYear: 2021,
        logoComponent: Logo,
        siteDomain: siteDomain,
    },

    actions: {
        endpoint: 'actions',
    },

    search: {
        endpoint: 'search',
        types: searchTypes,
    },

    auth: {
        loginHandler: Auth.login,
        authUserResolver: Auth.getAuth,
        logoutHandler: Auth.logout,
    },

    navigation: {
        navigationMenuResolver: () => ModulesRegistry.getNavigation(),
        globalButtonsResolver: () => ModulesRegistry.getGlobalButtons(),
        navigationCountersResolver: (app) => ModulesRegistry.getNavCounters(app),
        topbarButtonsResolver: async () => [
            {
                icon: 'application-import',
                title: 'Перейти на сайт',
                onClick: () => window.open(`https://${siteDomain}`, '_blank'),
                variant: 'link',
            }
        ],
    },

    external: {
        rtc: {
            rtcWsUrl: `wss://${adminDomain}/rtc/ws/`,
            baseEndpoint: 'collaboration',
        },
        media: {
            endpoint: 'media',
        },
        websocket: {
            host: adminDomain,
            port: '443',
            appKey: 'ws-admin',
            authEndpoint: '/api/broadcasting/auth',
        }
    }
}

const makeEngineAppConfig = (instanceConfig?: OvaEngineAppConfig, editorConfig?: OvaEngineEditorConfig) : AppConfig => ({
    ...appConfig,
    ...(instanceConfig ? {
        base: {
            ...appConfig.base,
            ...instanceConfig,
        },
    } : {}),
    ...(editorConfig ? {
        external: {
            ...appConfig.external,
            editor: editorConfig,
        },
    } : {}),
});

export { makeEngineAppConfig };
