import * as React from 'react';
import {ModuleConfig} from "../_registry";
import {makeSimpleRoute} from "@ova-studio/react-hyper-admin";

const Dashboard = React.lazy(() => import('./pages/Dashboard'));

const CoreModule : ModuleConfig = {
    routes: [
        makeSimpleRoute({
            index: true,
            name: 'Початкова сторінка',
            element: <Dashboard />
        })
    ]
}

export default CoreModule;
