import * as React from "react";
import BaseInfoPage from "../AppWrapper/InfoPages/BaseInfoPage";

import image from '../assets/images/maintenance.svg'

const MaintenancePage = () => {
    return (
        <BaseInfoPage
            image={image}
            title='Іде оновлення...'
            message='Сервіс тимчасово недоступний у зв’язку з оновленням програмного забезпечення. Просимо вибачення за тимчасові незручності та дякуємо за ваше розуміння! Спробуйте оновити сторінку пізніше.'
            btnLink={window.location.pathname}
            btnText='Оновити сторінку'
        />
    )
}

export default MaintenancePage;