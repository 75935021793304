import * as React from 'react';
import {useAppAuth} from "../../../Application";
import ErrorPageBody from "./ErrorPageBody";

const PageGuest = () => {
    const appAuth = useAppAuth();

    React.useEffect(() => {
        appAuth.logout()
            .then(() => window.location.reload());
    }, []);

    return (
        <ErrorPageBody
            info='Перенаправлення'
            message='Виконується перенаправлення, зачекайте...'
            showBackButton={false}
        />
    );
}

export default PageGuest
