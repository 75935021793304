import MainNavigation from "./MainNavigation";
import GlobalButtons from "./GlobalButtons";
import Topbar from "./Topbar";
import {SimpleCallback} from "../../types/SimpleCallback";
import {NavigateFunction, NavigateOptions, To} from "react-router-dom";
import {NavigationConfig} from "./types/NavigationConfig";
import {App} from "../../Application";

export default class Navigation {

    public readonly MainNavigation: MainNavigation;
    public readonly GlobalButtons: GlobalButtons;
    public readonly Topbar: Topbar;

    private readonly _app: App;
    private readonly _config: NavigationConfig;

    private _navigate : NavigateFunction|null = null;
    private _onDestroy : SimpleCallback|null = null;

    constructor(app: App, config: NavigationConfig) {
        this._app = app;
        this._config = config;
        this.MainNavigation = new MainNavigation(this._app, this._config);
        this.GlobalButtons = new GlobalButtons(this._app, this._config);
        this.Topbar = new Topbar(this._app, this._config);
    }

    public setNavigate(navigate : NavigateFunction) : SimpleCallback {
        this._navigate = navigate;
        return () => {
            this._navigate = null;
        }
    }

    // noinspection JSUnusedGlobalSymbols
    public navigate(to: To, options?: NavigateOptions) : void {
        if (this._navigate) {
            this._navigate(to, options);
        } else {
            console.error("navigate() failed: no navigate function set.");
        }
    }

    public async init() : Promise<void> {
        const destroyMainNavigation = await this.MainNavigation.init();
        const destroyGlobalButtons = await this.GlobalButtons.init();
        const destroyTopbar = await this.Topbar.init();

        this._onDestroy = () => {
            destroyMainNavigation();
            destroyGlobalButtons();
            destroyTopbar();
        }
    }

    public destroy() : void {
        if (this._onDestroy) {
            this._onDestroy();
        }
    }
}
