import * as React from 'react';
import ErrorPageBody from "./ErrorPageBody";

const Page403 = () => {
    return (
        <ErrorPageBody
            code='403'
            info='Доступ заборонено'
            message='Доступ в цей розділ потребує більш високих прав'
        />
    );
}

export default Page403
