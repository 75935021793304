import {ApiError, isApiError} from "@ova-studio/api-helper";

const STATUS_CODE = 426;

type FeatureUnavailableApiError = ApiError<any>

export const isFeatureUnavailableError = (e: unknown) : e is FeatureUnavailableApiError => {
    if (!isApiError(e)) return false;
    return e.response?.status === STATUS_CODE;
}
