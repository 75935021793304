import {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";

export interface UseModalOptions<T> {
    initialState?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    onExited?: () => void;
    initialData?: T | null;
    openAutoCheck?: (data: T|null) => boolean;
}

export interface UseModalReturn<T> {
    state: boolean;
    open: () => void;
    close: () => void;
    closeSilent: () => void;
    toggle: () => void;
    onExited?: () => void;
    data: T|null,
    setData: Dispatch<SetStateAction<T|null>>,
}

export default function useModal<T extends Record<string, any>>(params: UseModalOptions<T> = { initialState: false }) : UseModalReturn<T> {

    const [ state, setState ] = useState<boolean>(params.initialState ?? false);

    const [ data, setData ] = useState<T|null>(params.initialData ?? null);

    const open = useCallback(() => {
        setState(true);
        params.onOpen && params.onOpen();
    }, [ params.onOpen ]);

    const close = useCallback(() => {
        setState(false);
        params.onClose && params.onClose();
    }, [ params.onClose ]);

    const closeSilent = useCallback(() => {
        setState(false);
    }, [ ]);

    const toggle = useCallback(() => {
        setState(state => !state);
    }, [ ]);

    useEffect(() => {
        if (params.openAutoCheck) {
            const shouldOpen = params.openAutoCheck(data);
            shouldOpen ? open() : close();
        }
    }, [ data ]);

    return { state, open, close, closeSilent, toggle, data, setData, onExited: params.onExited }

}
