import * as React from 'react';
import {AppOptions} from "./types/AppOptions";
import App from "./App";
import AppContext from "./AppContext";
import AppRouter from "../AppWrapper/AppRouter";
import {AppHelpers} from "../AppHelpers";
import useExternalStore from "../hooks/useExternalStore";
import Preloader from "../components/Preloader";

const Application = (appOptions: AppOptions) => {

    const app = React.useMemo(() => new App(appOptions), []);

    const { lock: LockPage } = useExternalStore(() => app.errorHelper);

    return (
        <AppContext.Provider value={app}>
            {LockPage !== null ? (
                <React.Suspense fallback={<Preloader />}>
                    <LockPage />
                </React.Suspense>
            ) : (
                <React.Fragment>
                    <AppRouter />
                    <AppHelpers />
                </React.Fragment>
            )}
        </AppContext.Provider>
    );
}

export default Application
