import * as React from 'react';
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {$createCustomBlock, CustomBlockNode} from "./CustomBlockNode";
import {
    CREATE_BLOCK_COMMAND,
    DELETE_BLOCK_COMMAND,
    EDIT_BLOCK_COMMAND,
    INSERT_BLOCK_COMMAND, NEW_BLOCK_COMMAND,
    UPDATE_BLOCK_COMMAND
} from "./commands";
import {COMMAND_PRIORITY_EDITOR} from "lexical";
import {useModal} from "@ova-studio/react-hyper-admin";
import {BlockData} from "./types";
import BlockForm, {BlockFormData} from "./BlockForm";
import {$insertNodeToNearestRoot, mergeRegister} from "@lexical/utils";
import {useEditorContext} from "../../EditorContext";
import BlockSelector from "./BlockSelector";
import {filterListBlocks, hasSpecialBlocks} from "./utils";
import {TopbarInsertOption} from "../../Topbar/types";

const BlocksPlugin = () => {
    const [editor] = useLexicalComposerContext();

    const { config: { blocks }, topbarRegistry } = useEditorContext();

    if (!blocks || !blocks.length) {
        return false;
    }

    const listModalState = useModal();
    const blockModalState = useModal<BlockFormData<BlockData>>();

    React.useEffect(() => {
        if (!editor.hasNodes([ CustomBlockNode ])) {
            throw new Error("CustomBlockNode: node not registered on editor");
        }

        return mergeRegister(
            editor.registerCommand(
                NEW_BLOCK_COMMAND,
                () => {
                    listModalState.open();
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
            editor.registerCommand(
                INSERT_BLOCK_COMMAND,
                (name) => {
                    const block = blocks.find(b => b.name === name);
                    if (!block) return false;

                    if (block.initialData) {
                        editor.dispatchCommand(CREATE_BLOCK_COMMAND, { name, data: block.initialData })
                        return true;
                    }

                    if (!block.form) {
                        editor.dispatchCommand(CREATE_BLOCK_COMMAND, { name, data: {} })
                        return true;
                    }

                    blockModalState.setData({ block, data: undefined });
                    blockModalState.open();

                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
            editor.registerCommand(
                CREATE_BLOCK_COMMAND,
                ({ name, data }) => {
                    const block = blocks.find(b => b.name === name);
                    if (!block) return false;
                    $insertNodeToNearestRoot($createCustomBlock(name, data))
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
            editor.registerCommand(
                EDIT_BLOCK_COMMAND,
                ({ node }) => {
                    blockModalState.setData({
                        block: node.getBlockDefinition(),
                        data: node.getData(),
                        node,
                    });

                    blockModalState.open();
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
            editor.registerCommand(
                UPDATE_BLOCK_COMMAND,
                ({ node, data }) => {
                    node.updateData(data);
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
            editor.registerCommand(
                DELETE_BLOCK_COMMAND,
                ({ node }) => {
                    node.handleDelete();
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
            topbarRegistry.registerInserts(
                filterListBlocks(blocks).reduce<TopbarInsertOption[]>((data, { name, icon, title, isEnabledCheck }) => ([
                    ...data,
                    {
                        icon: icon ?? '',
                        label: title,
                        command: INSERT_BLOCK_COMMAND,
                        payload: name,
                        isEnableCheck: isEnabledCheck,
                    },
                ]), [])
            ),
            hasSpecialBlocks(blocks) ? topbarRegistry.registerInsert({
                icon: 'tab-plus',
                label: 'Вставити блок',
                command: NEW_BLOCK_COMMAND,
                payload: undefined,
            }) : () => {},
        );
    }, [editor]);

    return (
        <React.Fragment>
            <BlockSelector state={listModalState} />
            <BlockForm state={blockModalState} />
        </React.Fragment>
    );

}

export default BlocksPlugin
