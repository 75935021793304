import * as React from 'react';
import {Link} from "react-router-dom";
import FlexCentered from "../../../components/FlexCentered";

export type ErrorPageBodyProps = {
    code?: string,
    info: string,
    message?: string,
    variant?: 'danger' | 'info' | 'success' | 'warning',
    showBackButton?: boolean
}

const ErrorPageBody = ({ code, info, message, variant = 'danger', showBackButton = true }: ErrorPageBodyProps) => {
    return (
        <FlexCentered addClassName='gap-2' fullPage={true}>
            {code && <h1 className="text-error text-center">{code}</h1>}
            <h4 className={`text-uppercase text-${variant} text-center`}>{info}</h4>
            {message && <p className='text-center'>{message}</p>}

            {showBackButton && (
                <Link to='/' className='btn btn-info'>
                    <i className="mdi mdi-reply me-2"/>
                    Повернутись на початок
                </Link>
            )}
        </FlexCentered>
    );
}

export default ErrorPageBody
