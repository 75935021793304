import * as React from "react";
import {isNavMenuIcon, isNavMenuLink, NavMenuChildren, NavMenuItem} from "../../../../../AppHelpers/Navigation";
import {SimpleCallback} from "../../../../../types/SimpleCallback";
import {Dropdown, DropdownProps} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import useLongPress from "@utilityjs/use-long-press";
import classNames from "classnames";
import NavCounters from "../../NavCounters";

type BottomMenuItemWithChildrenProps = {
    data: NavMenuItem & NavMenuChildren,
    handleClick: SimpleCallback,
}

const BottomMenuItemWithChildren = ({ data }: BottomMenuItemWithChildrenProps) => {

    const [ show, setShow ] = React.useState(false);

    const handleToggle : DropdownProps['onToggle'] = (nextShow, meta) => {
        if (isNavMenuLink(data)) {
            if (meta.source === 'rootClose' || meta.source === 'select') {
                setShow(false);
            }
            return;
        }

        setShow(nextShow);
    }

    const { registerNode } = useLongPress(() => {
        setShow(true);
    }, { preventContextMenuOnLongPress: true, preventLongPressOnMove: true });

    const isLink = isNavMenuLink(data);

    return (
        <Dropdown show={show} onToggle={handleToggle}>
            <Dropdown.Toggle
                as={NavLink}
                to={isNavMenuLink(data) ? data.url : '#'}
                ref={registerNode}
                disabled={data.disabled}
                className={classNames('bottom-menu-item arrow-none', isLink && 'bottom-menu-item-link', data.disabled && 'bottom-menu-item-disabled')}
            >
                {isNavMenuIcon(data) && <i className={`mdi mdi-${data.icon}`} />}
                <span>
                    {data.name}
                    {data.counters && <NavCounters data={data.counters} />}
                </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {data.children.map((item, idx) => (
                    <Dropdown.Item key={`item-${idx}`} as={NavLink} to={isNavMenuLink(item) ? item.url : '#'} disabled={item.disabled}>{item.name}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default BottomMenuItemWithChildren;