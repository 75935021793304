import {MediaConfig} from "../config/MediaConfig";
import {defaultMediaConfig} from "../config/defaultMediaConfig";

const resolveMediaConfig = (instanceConfig?: MediaConfig|boolean) : MediaConfig => {
    if (instanceConfig === false) {
        return {};
    }

    if (instanceConfig === true) {
        return defaultMediaConfig;
    }

    return {
        ...defaultMediaConfig,
        ...instanceConfig,
    };
}

export default resolveMediaConfig;
