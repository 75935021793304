import * as React from 'react';
import {
    FlexCenteredInfo,
    FormSelectOption,
    Preloader,
    useSavedState
} from "@ova-studio/react-hyper-admin";
import {TopCntByEnum} from "../types/TopCntBy";
import {AnalyticsBaseData} from "../types/AnalyticsBaseData";
import API from "@ova-studio/api-helper";
import {Card, Col, FormLabel, FormSelect, Row, Table} from "react-bootstrap";

const INTERVALS: FormSelectOption[] = [
    { value: 15, label: '15 хвилин' },
    { value: 30, label: '30 хвилин' },
    { value: 60, label: '1 година' },
    { value: 1440, label: '1 день' },
    { value: 10080, label: '1 тиждень' },
    { value: 43200, label: '30 днів' },
];

type TableListProps<T extends Record<string, string|number>> = {
    data: T[],
    title: string,
    nameTitle: string,
    nameRender: (item: T) => React.ReactNode,
    cntName?: keyof T,
}

const TableList = <T extends Record<string, string|number>>({ data, title, nameTitle, nameRender, cntName = 'cnt' } : TableListProps<T>) => {
    return (
        <Card>
            <Card.Body>
                <h4 className="header-title mb-3">{title}</h4>

                {data.length > 0 ? (
                    <Table>
                        <thead>
                        <tr>
                            <th>{nameTitle}</th>
                            <th>Перегляди</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>{nameRender(item)}</td>
                                <td>{item[cntName]}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                ) : (
                    <FlexCenteredInfo
                        icon='test-tube-empty'
                        text='Немає даних'
                    />
                )}


            </Card.Body>
        </Card>
    )
}

const AnalyticsDashboard = () => {
    const [ interval, setShowInterval ] = useSavedState<number>(30, 'analytics::dashboard::interval');
    const [ topCntBy, _setTopCntBy ] = useSavedState<TopCntByEnum>(TopCntByEnum.Views, 'analytics::dashboard::top-cnt-by');

    const [ data, setData ] = React.useState<AnalyticsBaseData|null>(null);
    const refreshInterval = React.useRef<NodeJS.Timeout|null>(null);

    const loadData = React.useCallback(async () => {
        const response = await API.get('/analytics', { interval, topCntBy });
        setData(response.data);
    }, [interval, topCntBy]);

    React.useEffect(() => {

        if (refreshInterval.current) {
            clearInterval(refreshInterval.current);
        }

        loadData()
            .then(() => {
                refreshInterval.current = setInterval(() => {
                    void loadData();
                }, 5000);
            });

        return () => {
            if (refreshInterval.current) {
                clearInterval(refreshInterval.current);
            }
        }

    }, [interval, topCntBy]);

    if (!data) {
        return <Preloader />
    }

    return (
        <React.Fragment>
            <div className='d-flex justify-content-end align-items-center gap-1 mb-2'>
                <FormLabel className='mb-0' htmlFor='analytics::dashboard::interval'>Період</FormLabel>
                <FormSelect id='analytics::dashboard::interval' value={interval} onChange={e => setShowInterval(parseInt(e.target.value))} className='w-auto'>
                    {INTERVALS.map(({ value, label }) => (
                        <option key={value} value={value}>{label}</option>
                    ))}
                </FormSelect>
            </div>

            <Row>
                <Col>
                    <Card className='tilebox-one'>
                        <Card.Body>
                            <i className="uil uil-users-alt float-end"/>
                            <h6 className="text-uppercase mt-0">Користувачі</h6>
                            <h2 className="my-2">{data.users}</h2>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className='tilebox-one'>
                        <Card.Body>
                            <i className="uil uil-users-alt float-end"/>
                            <h6 className="text-uppercase mt-0">Перегляди</h6>
                            <h2 className="my-2">{data.views}</h2>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row xs={1} lg={2}>
                <Col>
                    <TableList
                        data={data.pages}
                        title='Топ сторінки'
                        nameTitle='Cторінка'
                        nameRender={(item) => <a href={item.page_url}>{item.page_title}</a>}
                    />
                </Col>
                {/*<Col>*/}
                {/*    <TableList*/}
                {/*        data={data.top_commented}*/}
                {/*        title='Найкоментованіші'*/}
                {/*        nameTitle='Публікація'*/}
                {/*        nameRender={(item) => <a href={item.page_url}>{item.page_title}</a>}*/}
                {/*    />*/}
                {/*</Col>*/}
                <Col>
                    <TableList
                        data={data.devices}
                        title='Пристрої'
                        nameTitle='Пристрій'
                        nameRender={(item) => <span>{item.device_type}</span>}
                    />
                </Col>
                {/*<Col>*/}
                {/*    <TableList*/}
                {/*        data={data.sources}*/}
                {/*        title='Джерела трафіку (за типом)'*/}
                {/*        nameTitle='Тип джерела'*/}
                {/*        nameRender={(item) => <span>{item.referrer_group}</span>}*/}
                {/*    />*/}
                {/*</Col>*/}
                {/*<Col>*/}
                {/*    <TableList*/}
                {/*        data={data.hosts}*/}
                {/*        title='Джерела трафіку (за доменом)'*/}
                {/*        nameTitle='Тип джерела'*/}
                {/*        nameRender={(item) => <span>{item.referrer_host}</span>}*/}
                {/*    />*/}
                {/*</Col>*/}
            </Row>
        </React.Fragment>
    );
}

export default AnalyticsDashboard
