import * as React from "react";
import {AppConfig, Application} from "@ova-studio/react-hyper-admin";
import {ModulesRegistry} from "./modules/_registry";
import './init';
import {makeEngineAppConfig, OvaEngineAppConfig, OvaEngineEditorConfig} from "./core/appConfig";
import onAppReady from "./core/onAppReady";

export type OvaEngineAdminProps = {
    config?: OvaEngineAppConfig,
    editorConfig?: OvaEngineEditorConfig,
}

const OvaEngineAdmin = ({ config: instanceConfig, editorConfig } : OvaEngineAdminProps) => {
    const config : AppConfig = React.useMemo(() => makeEngineAppConfig(instanceConfig, editorConfig), []);

    const appRoutes = React.useMemo(() => ModulesRegistry.getRoutes(), []);

    return (
        <Application
            appRoutes={appRoutes}
            config={config}
            onAppReady={onAppReady}
        />
    )
}

export default OvaEngineAdmin
