import * as React from 'react';
import ErrorPageBody from "./ErrorPageBody";

const Page404 = () => {
    return (
        <ErrorPageBody
            code='404'
            info='Сторінку не знайдено'
            message='Сторінка, яку ви шукаєте, не існує або була видалена'
        />
    );
}

export default Page404
