export enum UploadStatus {
    Uploading = 'uploading',
    WaitDuplicateConfirm = 'wait_duplicate_confirm',
    Success = 'success',
    Error = 'error',
}

export enum UploadType {
    File = 'file',
    Link = 'link',
}

export enum DuplicateAction {
    Reuse = 'reuse',
    Regenerate = 'regenerate',
    KeepBoth = 'keep_both',
}

export type UploadItemInfo = {
    type: UploadType,
    name: string,
    size?: number,
}

export type UploadItem = UploadItemInfo & {
    status: UploadStatus,
    progress: number,
    error?: string,
    duplicateHandler?: (action: DuplicateAction) => void,
}

export type RequestData = Record<string, any>

export type UploadingFiles = Record<string, UploadItem>

export type UploadTask = {
    info: UploadItemInfo,
    payload: RequestData,
}
